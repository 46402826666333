<template>
  <div data-cy="role-form">
    <b-row class="mb-7">
      <b-col sm="12" md="6" lg="12">
        <InputElement
          v-model="form.name"
          data-cy="name-input"
          required
          :with-error="errors.name"
          :error-message="$t('validation.required')"
          :label="$t('label.name')"
          @blur="removeErrorClass('name')"
          @input="removeErrorClass('name')"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="6" lg="12">
        <MultiselectElement
          :value="selectedType"
          data-cy="type-select"
          :options="roleTypes"
          :disabled="isExistRole"
          required
          :with-error="errors.typeId"
          :error-message="$t('validation.required')"
          :label="$t('label.type')"
          @select="onSelectType"
          @open="removeErrorClass('typeId')"
        />
      </b-col>
    </b-row>

    <template v-if="form.typeId">
      <div class="separator separator-dashed mt-10 mb-6" />

      <b-row class="check-box-wrap">
        <b-col>
          <p class="permissions-label">
            {{ $t("label.permission.list") }}
          </p>

          <b-form-checkbox-group
            v-model="form.selectedPermissions"
            :options="permissions"
            data-cy="permissions"
            value-field="id"
            text-field="label"
            stacked
            size="lg"
          />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputElement from "@/components/form/InputElement";
import MultiselectElement from "@/components/form/MultiselectElement";

export default {
  name: "RoleForm",
  components: {
    InputElement,
    MultiselectElement,
  },

  props: {
    role: {
      type: Object,
      default: () => ({}),
    },

    roles: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    permissions: [],
    form: {
      name: "",
      typeId: "",
      selectedPermissions: [],
    },
    errors: {
      name: false,
      typeId: false,
    },
  }),

  computed: {
    ...mapGetters("role", ["roleLabel"]),
    ...mapGetters("permission", ["permissionLabel"]),

    isExistRole() {
      return !!Object.keys(this.role).length;
    },

    selectedType() {
      return this.roleTypes.filter((type) => type.id === this.form.typeId);
    },

    roleTypes() {
      return this.roles
        .filter((role) => !role.isSelectable)
        .map((role) => ({
          ...role,
          label: this.roleLabel(role.name),
        }));
    },
  },

  watch: {
    "form.typeId": "onSelectRoleType",

    role: {
      handler: "onChangeRole",
      immediate: true,
      deep: true,
    },
  },

  methods: {
    onSelectRoleType(newType, oldType) {
      if (oldType) this.form.selectedPermissions = [];

      const permissionsData = this.roles.find((role) => role.id === newType).permissions;

      this.permissions = permissionsData.map((permission) => ({
        ...permission,
        label: this.permissionLabel(permission.name),
      }));
    },

    onChangeRole(roleData) {
      if (this.isExistRole) {
        this.form.name = roleData.name;
        this.form.typeId = roleData.parent.id;
        this.form.selectedPermissions = roleData.permissions.map((role) => role.id);
      }
    },

    onSelectType({ id }) {
      this.form.typeId = id;
    },

    isValidForm() {
      this.errors.name = !this.form.name;
      this.errors.typeId = !this.form.typeId;

      return !Object.values(this.errors).some((error) => error);
    },

    submitForm() {
      if (!this.isValidForm()) return;

      const name = this.form.name;
      const parent = this.form.typeId;
      const permissions = this.form.selectedPermissions;

      const roleData = {
        name,
        parent,
        permissions,
      };

      this.$emit("submitForm", roleData);
    },

    removeErrorClass(key) {
      this.errors[key] = false;
    },
  },
};
</script>

<style scoped lang="scss">
.form-group::v-deep {
  margin-bottom: 0;
}

.check-box-wrap::v-deep {
  .permissions-label {
    font-size: 1.07rem;
    color: #b5b5c3;
    margin-left: 25px;
  }

  .custom-checkbox {
    display: inline-block;
    min-width: 350px;
    margin-bottom: 3px;
  }
}
</style>
