import gql from "graphql-tag";

export const documentFormData = gql`
  query document($name: String) {
    document(name: $name) {
      name
      filename
      url
    }
  }
`;
