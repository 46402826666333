import gql from "graphql-tag";

export const roleFormData = gql`
  query roleFormData($id: ID!) {
    role(id: $id) {
      id
      name
      parent {
        id
        name
      }
      isSelectable
      permissions {
        id
        name
      }
      createdAt
      updatedAt
    }

    roles {
      id
      name
      isSelectable
      permissions {
        id
        name
      }
    }
  }
`;
