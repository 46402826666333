import gql from "graphql-tag";

export const createRole = gql`
  mutation createRole($name: String!, $parent: ID!, $permissions: [ID!]) {
    createRole(name: $name, parent: { associate: $parent }, permissions: { sync: $permissions }) {
      id
      name
      parent {
        id
        name
      }
      permissions {
        id
        name
      }
    }
  }
`;
