<template>
  <div>
    <KTCard>
      <DocumentInput name="brief" :file-data="brief" @change="changeBrief"/>
    </KTCard>
  </div>
</template>

<script>
import DocumentInput from './_components/DocumentInput.vue';
import KTCard from "@/components/card/KTCard";
import InputElement from '@/components/form/InputElement.vue';
import apolloMixin from "@/mixins/apollo.mixin";
import { documentFormData } from "./gql/queries";

export default {
  name: "Documents",

  data: () => ({
    brief: {},
  }),

  mixins: [apolloMixin],

  components: {
    KTCard,
    DocumentInput,
    InputElement,
  },

  async created() {
    await this.getBrief();
  },

  mounted() {
    this.$setBreadcrumbs();
  },

  methods: {
    changeBrief(e) {
      this.brief = e;
    },

    async getBrief() {
      const response = await this.$get(documentFormData, { name: "brief" });

      this.brief = response.document;
    },
  }
};
</script>
