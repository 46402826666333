import gql from "graphql-tag";

export const updateRole = gql`
  mutation updateRole($id: ID!, $name: String, $permissions: [ID!]) {
    updateRole(id: $id, name: $name, permissions: { sync: $permissions }) {
      id
      name
      parent {
        id
        name
      }
      permissions {
        id
        name
      }
    }
  }
`;

export const deleteRole = gql`
  mutation deleteRole($id: ID!) {
    deleteRole(id: $id) {
      id
      name
    }
  }
`;
