<template>
  <div data-cy="role-edit-page">
    <KTCard v-if="role">
      <RoleForm ref="roleForm" :role="role" :roles="roles" @submitForm="onSubmitForm" />

      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <div>
            <ButtonElement
              data-cy="submit-btn"
              variant="primary"
              text="save"
              class="mr-3"
              @click="onClickSubmit"
            />

            <ButtonElement
              data-cy="back-btn"
              variant="text-primary"
              text="back"
              @click="onClickBack"
            />
          </div>

          <ButtonElement
            v-b-modal.confirm-modal
            data-cy="delete-btn"
            variant="text-danger"
            text="delete"
          />
        </div>
      </template>
    </KTCard>

    <ConfirmModal
      :modal-title="$t('page.roleEdit.modalTitle')"
      is-centered
      @actionConfirmed="onConfirmDeleteRole"
    >
      <template v-slot:body>
        {{ $t("page.roleEdit.modalDescription") }}
      </template>
    </ConfirmModal>
  </div>
</template>

<script>
import apolloMixin from "@/mixins/apollo.mixin";
import { roleFormData } from "./gql/queries";
import { updateRole, deleteRole } from "./gql/mutations";

import KTCard from "@/components/card/KTCard";
import RoleForm from "../_components/RoleForm";
import ButtonElement from "@/components/ButtonElement";
import ConfirmModal from "@/components/modal/ConfirmModal";

const CONTENT_WIDTH = 800;

export default {
  name: "RoleEdit",
  components: {
    KTCard,
    RoleForm,
    ButtonElement,
    ConfirmModal,
  },

  mixins: [apolloMixin],

  data: () => ({
    roleId: null,
    role: null,
    roles: [],
  }),

  async created() {
    this.roleId = this.$route.params.id;

    await this.getFormData();
  },

  mounted() {
    this.$setBreadcrumbs(["RoleList"]);
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    async getFormData() {
      const { role, roles } = await this.$get(roleFormData, { id: this.roleId });

      this.role = role;
      this.roles = roles;
    },

    onClickSubmit() {
      this.$refs.roleForm.submitForm();
    },

    async onSubmitForm(roleData) {
      roleData = {
        id: this.roleId,
        ...roleData,
      };

      await this.$post(updateRole, roleData);
    },

    onClickBack() {
      this.$router.push({ name: "RoleList" });
    },

    async onConfirmDeleteRole() {
      const deletedRole = await this.$post(
        deleteRole,
        { id: this.roleId },
        { delaySuccessNotify: true }
      );

      if (deletedRole) await this.$router.push({ name: "RoleList" });
    },
  },
};
</script>
