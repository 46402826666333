import gql from "graphql-tag";

export const roleList = gql`
  query roles {
    roles {
      id
      name
      isSelectable
      permissions {
        id
        name
      }
    }
  }
`;
