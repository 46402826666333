<template>
  <div data-cy="employee-roles-page">
    <ButtonElement
      data-cy="add-role-btn"
      variant="primary"
      text="add"
      class="mb-4"
      @click="onClickAddRole"
    />

    <KTCard card-class="card-table">
      <OurTable data-cy="roles-table" table-classes="our-table" :items="roleList" :fields="fields">
        <template v-slot:cell-actions="{ scopeProps }">
          <SvgIcon
            data-cy="edit-role-btn"
            icon-folder="Design"
            icon-name="Edit"
            icon-color="primary"
            icon-size="md"
            @click="onClickEditRole(scopeProps.item)"
          />
        </template>
      </OurTable>
    </KTCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { roleList } from "./gql/queries";

import KTCard from "@/components/card/KTCard";
import OurTable from "@/components/table/OurTable";
import SvgIcon from "@/components/svg/SvgIcon";
import ButtonElement from "@/components/ButtonElement";

const CONTENT_WIDTH = 700;

export default {
  name: "RoleList",
  components: {
    KTCard,
    OurTable,
    SvgIcon,
    ButtonElement,
  },

  mixins: [apolloMixin],

  data() {
    return {
      roles: [],
      fields: [
        {
          key: "label",
          label: this.$t("label.role._"),
          tdAttr: {
            style: "max-width: 110px;",
          },
        },
        {
          key: "actions",
          label: "",
          tdAttr: {
            style: "width: 60px;",
          },
        },
      ],
    };
  },

  computed: {
    ...mapGetters("role", ["roleLabel"]),

    roleList() {
      return this.roles
        .filter((role) => role.isSelectable)
        .map((role) => ({
          ...role,
          label: this.roleLabel(role.name),
        }));
    },
  },

  async created() {
    await this.getRoles();
  },

  mounted() {
    this.$setBreadcrumbs();
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    async getRoles() {
      const { roles } = await this.$get(roleList);

      this.roles = roles;
    },

    onClickAddRole() {
      this.$router.push({ name: "RoleAdd" });
    },

    onClickEditRole({ id }) {
      this.$router.push({ name: "RoleEdit", params: { id } });
    },
  },
};
</script>
