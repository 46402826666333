<template>
  <div :class="{'input-file form-control': true, 'error': error}">
    <div class="input-file-label">{{ $t("form.brief") }}</div>
    <div>
      <div class="input-file-value-row">
        <SvgIcon
          icon-folder="General"
          icon-name="File"
          icon-size="xl"
        />
        <div class="input-file-value">{{ fileData?.filename || $t("form.inputFileEmpty") }}</div>
      </div>
      <div :class="{'input-file-desc': true, 'error': error}">{{ $t("form.allowedExt") }}: pdf, doc, docx</div>
    </div>
    <div class="input-file-actions">
      <label class="input-file-label-upload" :for="_uid">
        <div class="input-file-btn btn font-weight-bold px-6 mr-3 btn-primary btn-shadow-hover btn-md">
          {{ $t("button.upload") }}
        </div>
        <input type="file" :id="_uid" class="input-file-hidden" @change="change" accept=".pdf, .doc, .docx">
      </label>
      <button 
        class="input-file-btn btn font-weight-bold px-6 mr-3 btn-primary btn-shadow-hover btn-md"
        @click="download"
      >{{ $t("button.download") }}</button>
      <div class="input-file-btn btn-danger btn" @click="remove">{{ $t("button.delete") }}</div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/svg/SvgIcon";
import axios from "axios"

export default {
  name: "DocumentInput",

  components: {
    SvgIcon,
  },

  props: {
    name: {
      type: String,
      default: "",
    },

    fileData: {
      type: Object,
      default: () => {},
    }
  },

  data() {
    return {
      error: false,
    }
  },

  methods: {
    async change(e) {
      const formData = new FormData();
      const file = e.target;

      formData.append("file", file.files[0]);

      if(this.name) {
        formData.append("name", this.name);
      }

      if(typeof file.files[0] !== "undefined") {

        const accept = ["pdf", "doc", "docx"];
        const ext = file.files[0].name.split(".")[file.files[0].name.split(".").length - 1].toLowerCase();

        if(!accept.includes(ext)) {
          this.error = true;

          return;
        }

        this.error = false;

        const response = await axios({
          url: "/files/documents",
          headers: {
              "Content-Type": "multipart/form-data",
          },
          data: formData,
          method: "POST",
        })

        this.$emit("change", response.data);
      }
    },

    download() {
      if(this.fileData.url) {

        axios.get(`storage/private/${this.fileData.url}`, {
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');

          link.href = href;
          link.setAttribute("download", this.fileData.filename);
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });

      }
    },

    async remove() {
      if(!this.fileData.name) {
        return;
      }

      await axios({
        url: "/files/documents",
        method: "DELETE",
        data: {
          name: this.fileData.name,
        }
      });

      this.fileData = {};
    }

  },
};
</script>

<style lang="scss" scoped>
.input-file {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &.error {
    border-color: red;
  }

  .form-group {
    margin: 0 1.5rem !important;
    flex: 100 0 0;
  }
  label {
    margin-bottom: 0;
  }
  &-label {
    font-size: 1.07rem;
    margin-left: 5px;
    padding: 0 5px;
    position: absolute;
    left: 10px;
    top: -15px;
    background-color: white;
    border-radius: 0.25rem;
    z-index: 1;
    font-weight: 400;
    color: #B5B5C3;
  }
  &-hidden {
      display: none;
  }
  &-desc {
    position: absolute;
    bottom: 4px;
    left: 20px;
    font-size: 12px;
    color: #B5B5C3;

    &.error {
      color: red;
    }
  }
  &-actions {
      display: flex;
      align-items: center;
      height: 100%;
      gap: 0 0.5rem;
  }
  &-btn {
    border-radius: 4px;
    padding: 1rem;
    height: 40px;
    min-width: 42px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &-value {
    &-row {
      display: flex;
      align-items: center;
      cursor: pointer;

      .svg-icon {
        padding: 0;
        margin-right: .5rem;
      }
    }
  }
}
</style>
